import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = (props) => {
  const location = useLocation()
  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'auto';
    window.scrollTo(0, 0)
    //window.scrollTo({ top: 0, behavior: "smooth" })
  }, [location])

  return <>{props.children}</>
}

export default ScrollToTop
