import React from "react";
import "./style.scss";

const Reason = () => {
  return (
    <div className="reason">
      <h2>Lý do khách hàng chọn biển quảng cáo bmt</h2>
      <div className="reason_box">
        <div className="uytin">
          <img
            className="img-logo"
            src={
              process.env.PUBLIC_URL + "/images/uytin-bien-quang-cao-bmt.png"
            }
            alt="thi-cong-bien-quang-cao-uy-tin-bmt-daklak"
          />
          <p>Uy tín-Chất lượng </p>
          <div className="reason-des">
            <span>
              Uy tín là yếu tố hàng đầu, đạo đức sự tận tâm thể hiện qua chất
              lượng của từng sản phẩm.
            </span>
          </div>
        </div>

        <div className="giare">
          <img
            className="img-logo"
            src={
              process.env.PUBLIC_URL + "/images/giare-bien-quang-cao-bmt.png"
            }
            alt="thi-cong-bien-quang-cao-gia-re-bmt-daklak"
          />
          <p>Giá rẻ nhất</p>
          <div className="reason-des">
            <span>
              Cam kết giá thành rẻ nhất thị trường, yếu tố cạnh tranh thật sự
              không công bằng của chúng tôi.
            </span>
          </div>
        </div>

        <div className="phucvu">
          <img
            className="img-logo"
            src={
              process.env.PUBLIC_URL + "/images/phucvu-bien-quang-cao-bmt.png"
            }
            alt="lam-bang-hieu-tan-tam-bmt-daklak"
          />
          <p>Phục vụ 24/7</p>
          <div className="reason-des">
            <span>
              Sẵn sàng đáp ứng dịch vụ 24/7, miễn phí tư vấn báo giá tận nơi,
              làm việc cả ngày lễ. Nhận thiết kế làm bảng hiệu cho khách hàng
              trên toàn quốc.
            </span>
          </div>
        </div>

        <div className="nhanvien">
          <img
            className="img-logo"
            src={
              process.env.PUBLIC_URL + "/images/nhanvien-bien-quang-cao-bmt.png"
            }
            alt="lam-bien-quang-cao-chuyen-nghiep-bmt-daklak"
          />
          <p>Chuyên nghiệp</p>
          <div className="reason-des">
            <span>
              Sở hữu đội ngũ nhân viên lành nghề, luôn là một trong những đơn vị
              thi công, thiết kế làm biển quảng cáo hàng đầu tại Đắk Lắk, BMT.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reason;
