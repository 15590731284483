import React, { useState } from "react";
import "./style.scss";

const Youtub = () => {
  const dataVideo = [
    {
      id: 1,
      name: "Thi công Biển Quảng Cáo tại xưởng - Quảng cáo BMT - LH: 096.4040.895",
      code: "W089u48OrIQ",
    },
    {
      id: 2,
      name: "Tháo lắp Bảng hiệu cho khách - Quảng cáo BMT - LH: 096.4040.895",
      code: "mlIjApTr42Y",
    },
  ];

  const [video, setVideo] = useState(dataVideo[0].code);

  const handleChange = (e) => {
    setVideo(e.target.value);
  };

  return (
    <div className="youtube">
      <h2>Công trình đã thi công</h2>
      <div className="youtub-video">
        <iframe
          className="shadow-1-strong rounded"
          src={`https://www.youtube.com/embed/${video}`}
          title="Biển quảng cáo BMT"
          allowFullScreen
          width="100%"
          height="100%"
        />
      </div>
      <form className="youtub-form">
        <select onChange={handleChange} value={video}>
          {dataVideo.map((video) => (
            <option value={video.code} key={video.id}>
              {video.name}
            </option>
          ))}
        </select>
      </form>
    </div>
  );
};

export default Youtub;
