import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addConstruction,
  deleteConstruction,
  getConstruction,
  handleDataTinhCong,
  updateConstruction,
} from "./thongkeSlice";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import SetPrice from "../util/set-price";
import Moment from "../util/moment";
import XacNhanXoa from "../util/xac-nhan-xoa";
import SetModalNote from "../util/set-modal-note";
import { numberToWordsVietnamese } from "../util/number-to-words-vietnamese";
import * as XLSX from "xlsx";
import "./style.scss";
import { useNavigate } from "react-router-dom";

const FormThongke = () => {
  const dataTinhCongDB = useSelector(handleDataTinhCong);

  const dispatch = useDispatch();

  useEffect(() => {
    const promise = dispatch(getConstruction());
    return () => {
      promise.abort();
    };
  }, [dispatch]);

  const [showModal, setShowModal] = useState({
    display: "none",
    id_ct: "",
  });

  const initialCt = {
    id_ct: "",
    address_ct: "",
    time_ct: new Date().toISOString().split("T")[0],
    type_ct: "bang_hieu",
    name_khach: "",
    phone_khach: "",
    price_ct: "",
    unit_ct: "",
    note_ct: "",
    cong: [],
  };

  const [ct, setCt] = useState(initialCt);

  const isPhone = (value) => /0[0-9]{9}$/.test(value.trim());

  const handleFormThongKe = (e) => {
    const newCt = {
      ...ct,
      [e.target.name]: e.target.value,
    };
    setCt(newCt);
  };

  const [isUpdate, setIsUpdate] = useState(false);

  const submitFormThongKe = async (e) => {
    e.preventDefault();
    let sixDigitRandomNumber = Math.floor(100000 + Math.random() * 900000);
    const ct_finish = {
      ...ct,
      // id_ct: isUpdate ? ct.id_ct : `ct_${sixDigitRandomNumber}`,
      price_ct: ct.price_ct,
      unit_ct: `${ct.unit_ct || "?"} ${get_unit_type_cong_trinh()}`,
    };
    // isUpdate ? dispatch(update_ct(ct_finish)) : dispatch(add_ct(ct_finish));
    // isUpdate && setIsUpdate(false);
    delete ct_finish._id; //Xóa cái _id này rồi chuyền vào cho backend ko là nó lỗi, thằng con cũng có _id nhưng không cần xóa

    resetForm();
    isUpdate
      ? await dispatch(updateConstruction(ct_finish))
      : await dispatch(addConstruction(ct_finish));
    isUpdate && setIsUpdate(false);
  };

  const resetForm = () => {
    setCt(initialCt);
  };

  const handleUpdate = (i) => {
    setIsUpdate(true);
    setCt({ ...i, unit_ct: i.unit_ct.split(" ")[0] });
  };

  const handleCancelUpdate = (i) => {
    setIsUpdate(false);
    setCt(initialCt);
  };

  const handleDelete = async (id) => {
    await dispatch(deleteConstruction(id)).then(() => {
      // dispatch(delete_ct(id));
      setCt(initialCt);
      setIsUpdate(false);
    });
  };

  const type_cong_trinh = [
    {
      id: 0,
      value: "bang_hieu",
      name: "Bảng hiệu",
      unit: "m2",
    },
    {
      id: 1,
      value: "in_an",
      name: "In ấn",
      unit: "cái",
    },
    {
      id: 2,
      value: "backrop",
      name: "Backdrop sự kiện",
      unit: "m2",
    },
    {
      id: 3,
      value: "trien_lam",
      name: "Triển lãm",
      unit: "m2",
    },
    {
      id: 4,
      value: "cat_cnn",
      name: "Cắt CNC",
      unit: "cái",
    },
    {
      id: 5,
      value: "khac",
      name: "Khác",
      unit: "...",
    },
  ];
  const get_unit_type_cong_trinh = () => {
    const valueSearch = type_cong_trinh.find(
      (i, index) => i.value == ct.type_ct
    );

    if (valueSearch?.unit == "m2") {
      return "m\u00B2";
    }

    return valueSearch?.unit;
  };

  const get_name_type_cong_trinh = (value) => {
    return type_cong_trinh.find((i, index) => i.value == value)?.name;
  };

  //Phần mật khẩu----------------------
  //Xử lý nhập mật khẩu mới vào trang thống kê được
  //Nếu như nhập đúng thì sẽ lưu mật khẩu vào máy, sau đó mỗi lần nếu còn mất khẩu sẽ tự động vào
  let matkhau = "quangcaobmt";
  const [passThongKe, setPassThongKe] = useState();
  useEffect(() => {
    setPassThongKe(
      typeof window !== "undefined" &&
        JSON.parse(localStorage.getItem("p_VSCN"))
    );
  }, []);

  const [passInput, setPassInput] = useState();
  const handleChangPass = (e) => {
    setPassInput(e.target.value);
  };
  const handleSubmitPass = (e) => {
    e.preventDefault();
    setPassThongKe(passInput);
    if (passInput == matkhau)
      localStorage.setItem("p_VSCN", JSON.stringify(passInput));
  };

  //Phần ghi chú---------------------
  const [showModalNote, setShowModalNote] = useState({
    isShow: false,
    value: "",
  });

  //Phần xử lý lọc-----------------------------
  const [dataTinhCong, setDataTinhCong] = useState();
  const [dataTinhCong_2, setDataTinhCong_2] = useState();

  useEffect(() => {
    setDataTinhCong(getDataFilterDate(dataTinhCongDB, getMonthNow()));
    setDataTinhCong_2(dataTinhCongDB);
  }, [dataTinhCongDB]);

  //Lấy tháng năm hiện tại
  const getMonthNow = () => {
    var currentDate = new Date();
    // Lấy tháng và năm từ ngày tháng hiện tại
    var currentMonth = currentDate.getMonth() + 1; // Lấy tháng (tháng bắt đầu từ 0)
    var currentYear = currentDate.getFullYear(); // Lấy năm
    // Định dạng tháng và năm thành chuỗi "YYYY-MM"
    var defaultMonthYear = currentYear + "-" + ("0" + currentMonth).slice(-2); // Chuỗi phải có dạng "YYYY-MM"
    return defaultMonthYear;
  };

  //Các trường lọc
  const [propLoc, setPropLoc] = useState({
    type: "all",
    date: getMonthNow(),
  });

  const handleChangeType = (e) => {
    setPropLoc((pre) => {
      return { ...pre, type: e.target.value };
    });
    handleFilterTable(e.target.value, propLoc.date);
  };

  //Xử lý khi lọc thời gian
  const handleChangeDate = (e) => {
    setPropLoc((pre) => {
      return { ...pre, date: e.target.value };
    });
    handleFilterTable(propLoc.type, e.target.value);
  };

  const handleFilterTable = (type_value, date_value) => {
    if (!date_value) {
      if (type_value == "all") {
        setDataTinhCong(dataTinhCong_2);
      } else {
        const locDataTinhCong = dataTinhCong_2.filter(
          (i, index) => i.type_ct == type_value
        );

        setDataTinhCong(locDataTinhCong);
      }
    } else {
      if (type_value == "all") {
        setDataTinhCong(getDataFilterDate(dataTinhCong_2, date_value));
      } else {
        const locDataTinhCong = dataTinhCong_2.filter(
          (i, index) => i.type_ct == type_value
        );

        setDataTinhCong(getDataFilterDate(locDataTinhCong, date_value));
      }
    }
  };

  const getDataFilterDate = (data, valueInput) => {
    const inputMonthValue = valueInput;
    // Chuyển đổi giá trị thành ngày tháng
    const selectedDate = new Date(inputMonthValue);
    // Lấy ngày và tháng từ ngày tháng đã chọn
    const selectedMonth = selectedDate.getMonth() + 1; // Tháng trong JavaScript bắt đầu từ 0
    const selectedYear = selectedDate.getFullYear();
    const locDataTinhCong = data.filter((i, index) => {
      //Từ dữ liệu lên
      const date = new Date(i.time_ct);
      // Lấy tháng và năm từ đối tượng ngày tháng
      const month = date.getMonth() + 1; // Lấy tháng (tháng bắt đầu từ 0)
      const year = date.getFullYear(); // Lấy năm
      return selectedMonth == month && selectedYear == year;
    });
    return locDataTinhCong;
  };

  //Phần tải file excel dữ liệu chuyển từ Json----\
  const handleDownloadExcel = () => {
    const transformedData = dataTinhCong_2.map((item) => {
      const news = {
        "Thời gian": Moment(item.time_ct),
        "Địa chỉ": item.address_ct,
        Loại: get_name_type_cong_trinh(item.type_ct),
        "Tên khách": item.name_khach,
        SĐT: item.phone_khach,
        "Diện tích": item.unit_ct,
        "Giá thành": SetPrice(parseFloat(item.price_ct) * 1000),
        "Chi phí": SetPrice(
          item.cong?.reduce(
            (tong, item) => tong + parseFloat(item.amount) * 1000,
            0
          )
        ),
        "Lợi nhuận": SetPrice(
          (item.price_ct -
            item.cong.reduce(
              (tong, item) => tong + parseFloat(item.amount),
              0
            )) *
            1000
        ),
        "Tính công": item.cong
          .map((likeItem) =>
            Object.values({
              Thợ: likeItem.name,
              "Số công": `${likeItem.merit}c`,
              "Giờ tăng ca": `${likeItem.overtime}h`,
              "Tiền thêm": `${likeItem.add_price}k`,
              Nhận: SetPrice(parseFloat(likeItem.amount) * 1000),
            }).join(", ")
          )
          .join(" --- "),
      };
      return news;
    });
    let worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Excel_Vesinhcongnghiep.xlsx");
  };

  //Phần nhấn vào copy sđt------
  const [isCopy, setIsCopy] = useState({
    show: false,
    id_ct: "",
  });
  const copyToClipboard = (event, id_ct) => {
    const textToCopy = event.target.innerText;

    // Tạo một input ẩn để copy nội dung vào clipboard
    const input = document.createElement("input");
    input.value = textToCopy;
    document.body.appendChild(input);

    // Chọn và sao chép nội dung vào clipboard
    input.select();
    document.execCommand("copy");

    // Xóa input sau khi đã sao chép
    document.body.removeChild(input);

    // alert("Đã sao chép SĐT: " + textToCopy);
    setIsCopy({
      show: true,
      id_ct: id_ct,
    });

    setTimeout(() => {
      setIsCopy({
        show: false,
        id_ct: "",
      });
    }, 1200);
  };

  const loinhuan = (data) => {
    return data.reduce(
      (tong1, currentValue) =>
        tong1 +
        (parseFloat(currentValue.price_ct) -
          parseFloat(
            currentValue.cong.reduce(
              (tong2, item) => tong2 + parseFloat(item.amount),
              0
            )
          )) *
          1000,
      0
    );
  };

  const doanhthu = (data) => {
    return data.reduce(
      (tong1, currentValue) => tong1 + parseFloat(currentValue.price_ct) * 1000,

      0
    );
  };

  const tile = (data) => {
    return `${Math.round((loinhuan(data) / doanhthu(data)) * 100)}%`;
  };

  //Phần khi chuyển sang trang tính công
  const navigate = useNavigate();

  const handleClick = (value) => {
    navigate("/thong-ke/tinh-cong", { state: { data: value } });
  };

  return (
    <>
      {passThongKe != matkhau && (
        <form className="form_pass" onSubmit={(e) => handleSubmitPass(e)}>
          <input
            type="text"
            placeholder="Nhập mật khẩu..."
            onChange={(e) => handleChangPass(e)}
          />
          <button type="submit">Đi</button>
        </form>
      )}

      {passThongKe == matkhau && (
        <div>
          {/* <Head>
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
            />
          </Head> */}

          <div className="form_tk_box">
            <form className="form_tk" onSubmit={(e) => submitFormThongKe(e)}>
              <div className="form_tk_row">
                <div className="form_tk_row_left">
                  <span>Thời gian:</span>
                </div>
                <div className="form_tk_row_right">
                  <input
                    type="date"
                    name="time_ct"
                    onChange={(e) => handleFormThongKe(e)}
                    value={ct.time_ct}
                    style={{ color: "#333" }}
                  />
                </div>
              </div>
              <div className="form_tk_row">
                <div className="form_tk_row_left">
                  <span>Địa chỉ:</span>
                </div>
                <div className="form_tk_row_right">
                  <input
                    type="text"
                    name="address_ct"
                    onChange={(e) => handleFormThongKe(e)}
                    value={ct.address_ct}
                  />
                </div>
              </div>
              <div className="form_tk_row">
                <div className="form_tk_row_left">
                  <span>Công trình:</span>
                </div>
                <div
                  className="form_tk_row_right"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <select
                    name="type_ct"
                    onChange={(e) => handleFormThongKe(e)}
                    value={ct.type_ct}
                    defaultValue="bang_hieu"
                    style={{ height: 33, width: "50%", color: "#333" }}
                  >
                    {type_cong_trinh.map((i, index) => (
                      <option value={i.value} key={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      type="text"
                      style={{ width: "50%", margin: "0 8px" }}
                      name="unit_ct"
                      onChange={(e) => handleFormThongKe(e)}
                      value={ct.unit_ct}
                    />
                    <input
                      type="text"
                      style={{
                        width: "50%",
                        textAlign: "center",
                        color: "#b4b2b2",
                        fontWeight: "500",
                      }}
                      value={get_unit_type_cong_trinh()}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="form_tk_row">
                <div className="form_tk_row_left">
                  <span>Tên khách:</span>
                </div>
                <div className="form_tk_row_right db">
                  <input
                    type="text"
                    name="name_khach"
                    onChange={(e) => handleFormThongKe(e)}
                    value={ct.name_khach}
                    className={ct.phone_khach == " " && "form_tk_input_warm"}
                  />
                </div>
              </div>
              <div className="form_tk_row">
                <div className="form_tk_row_left">
                  <span>SĐT:</span>
                </div>
                <div className="form_tk_row_right db">
                  <input
                    type="text"
                    name="phone_khach"
                    onChange={(e) => handleFormThongKe(e)}
                    value={ct.phone_khach}
                    className={
                      !isPhone(ct.phone_khach) &&
                      ct.phone_khach != "" &&
                      "form_tk_input_warm"
                    }
                  />
                </div>
              </div>
              <div className="form_tk_row">
                <div className="form_tk_row_left">
                  <span> Giá thành:</span>
                </div>

                <div
                  className="form_tk_row_right"
                  style={{ position: "relative" }}
                >
                  <input
                    type="text"
                    name="price_ct"
                    onChange={(e) => handleFormThongKe(e)}
                    value={ct.price_ct}
                    style={{ width: "40%" }}
                  />
                  <span
                    style={{
                      width: "60%",
                    }}
                  >
                    .000 &#8363;
                  </span>
                  <br />
                  <i
                    style={{
                      fontSize: 12,
                      color: "#ccc",
                      marginTop: 6,
                      position: "absolute",
                      top: "100%",
                    }}
                  >
                    {ct.price_ct != "" &&
                      /^[0-9]+$/.test(ct.price_ct) &&
                      `( ${numberToWordsVietnamese(
                        parseInt(`${ct.price_ct}000`)
                      )} VNĐ )`}
                  </i>
                </div>
              </div>
              <br />
              <div className="form_tk_row">
                <div className="form_tk_row_right">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      !(
                        isPhone(ct.phone_khach) &&
                        ct.address_ct != "" &&
                        ct.name_khach != "" &&
                        ct.price_ct != ""
                      )
                    }
                    style={{ width: "100%", fontWeight: 700 }}
                  >
                    {isUpdate ? "Lưu" : "Thêm"}
                  </Button>
                </div>

                <div className="form_tk_row_left">
                  {isUpdate && (
                    <Button
                      variant="secondary"
                      style={{ marginLeft: 8, width: "100%" }}
                      onClick={() => handleCancelUpdate()}
                    >
                      Hủy sửa
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="table_box">
            {dataTinhCongDB?.length == 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 36,
                }}
              >
                <Spinner
                  animation="border"
                  style={{
                    color: "#ccc",
                  }}
                />
              </div>
            )}
            {!dataTinhCongDB?.length == 0 && (
              <table className="table_form">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>
                      <select
                        name="type_ct"
                        onChange={(e) => handleChangeType(e)}
                        className="filter_type"
                      >
                        <option value="all">Tất cả</option>
                        {type_cong_trinh.map((i, index) => (
                          <option value={i.value} key={i.id}>
                            {i.name}
                          </option>
                        ))}
                      </select>
                    </th>
                    <th>Thời gian </th>
                    <th>Địa chỉ </th>
                    <th>Loại</th>
                    <th>Tên khách</th>
                    <th>SĐT</th>

                    <th>Diện tích</th>
                    <th>Giá thành</th>
                    <th>Chi phí</th>
                    <th>Lợi nhuận</th>
                    <th colSpan="3" style={{ textAlign: "center" }}>
                      <input
                        type="month"
                        id="month"
                        name="month"
                        className="filter_date"
                        defaultValue={getMonthNow()}
                        onChange={(e) => handleChangeDate(e)}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataTinhCong?.length == 0 && (
                    <tr
                      style={{
                        textAlign: "center",
                        marginTop: 24,
                        color: "gray",
                        width: "100px",
                      }}
                    >
                      <td colSpan="11">Không có dữ liệu</td>
                    </tr>
                  )}
                  {dataTinhCong?.map((i, index) => {
                    return (
                      <tr key={i.id_ct}>
                        <td>
                          <button
                            onClick={() => handleClick(i.id_ct)}
                            className={i.cong?.length == 0 && "viendo"}
                          >
                            Tính công
                          </button>
                        </td>
                        <td>{Moment(i.time_ct)}</td>
                        <td>{i.address_ct}</td>
                        <td>{get_name_type_cong_trinh(i.type_ct)}</td>
                        <td>{i.name_khach}</td>
                        <td
                          onClick={(e) => copyToClipboard(e, i.id_ct)}
                          style={{ position: "relative" }}
                        >
                          {i.phone_khach}
                          <div
                            className="copy_info"
                            style={{
                              display:
                                isCopy.show && isCopy.id_ct == i.id_ct
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Đã sao chép
                          </div>
                        </td>

                        <td>{i.unit_ct}</td>
                        <td>{SetPrice(i.price_ct * 1000)}</td>
                        <td>
                          {SetPrice(
                            i.cong?.reduce(
                              (tong, item) =>
                                tong + parseFloat(item.amount) * 1000,
                              0
                            )
                          )}
                        </td>
                        <td>
                          {SetPrice(
                            (i.price_ct -
                              i.cong.reduce(
                                (tong, item) => tong + parseFloat(item.amount),
                                0
                              )) *
                              1000
                          )}
                        </td>
                        <td>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <button
                              style={{ margin: "0px 8px" }}
                              onClick={() => {
                                setShowModalNote((pre) => {
                                  return {
                                    isShow: !pre.isShow,
                                    value: i,
                                  };
                                });
                              }}
                            >
                              Ghi chú
                            </button>
                            {showModalNote.isShow &&
                              i.id_ct == showModalNote.value.id_ct && (
                                <SetModalNote
                                  value={showModalNote.value}
                                  setShowModalNote={setShowModalNote}
                                />
                              )}
                            {/* Cái này để khi nhấn ra bên ngoài thì nó tự tắt */}
                            {showModalNote.isShow && (
                              <div
                                className="man_che_phu"
                                onClick={() =>
                                  setShowModalNote({
                                    isShow: false,
                                    value: "",
                                  })
                                }
                              ></div>
                            )}
                          </div>
                        </td>
                        <td>
                          {!isUpdate && (
                            <button
                              style={{ margin: "0px 8px" }}
                              onClick={() => {
                                handleUpdate(i);
                              }}
                            >
                              Sửa
                            </button>
                          )}
                          {isUpdate && ct.id_ct == i.id_ct && (
                            <button
                              style={{ margin: "0px 8px" }}
                              onClick={() => handleCancelUpdate(i)}
                            >
                              Hủy sửa
                            </button>
                          )}

                          {isUpdate && ct.id_ct != i.id_ct && (
                            <button
                              style={{ margin: "0px 8px" }}
                              onClick={() => handleUpdate(i)}
                            >
                              Sửa
                            </button>
                          )}
                        </td>
                        <td>
                          <XacNhanXoa ham_xoa={handleDelete} id_xoa={i.id_ct}>
                            <button style={{ position: "static" }}>Xóa</button>
                          </XacNhanXoa>
                        </td>
                      </tr>
                    );
                  })}
                  {dataTinhCong?.length != 0 && (
                    <tr style={{ fontWeight: 700 }}>
                      <td colSpan={2}>
                        Tổng số công trình: {dataTinhCong.length}
                      </td>
                      <td colSpan={8}>
                        Tổng lợi nhuận: &nbsp;
                        {SetPrice(loinhuan(dataTinhCong))}
                        &emsp;:&emsp;({tile(dataTinhCong)}&nbsp;|&nbsp;
                        {SetPrice(doanhthu(dataTinhCong))})
                      </td>
                      <td colSpan={3} style={{ textAlign: "center" }}>
                        <button onClick={() => handleDownloadExcel()}>
                          Tải về file Excel
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FormThongke;
