import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addConstruction_cong,
  deleteConstruction_cong,
  getConstruction,
  handleDataTinhCong,
  updateConstruction_cong,
} from "./thongkeSlice";
import Button from "react-bootstrap/Button";
import SetPrice from "../util/set-price";
import XacNhanXoa from "../util/xac-nhan-xoa";
import { numberToWordsVietnamese } from "../util/number-to-words-vietnamese";
import "./style.scss";
import Moment from "../util/moment";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const FormTinhCong = () => {
  const { state } = useLocation();
  const id_ct = state?.data;

  const dispatch = useDispatch();
  const dataTinhCong = useSelector(handleDataTinhCong).find((i, index) => {
    return i.id_ct == id_ct;
  })?.cong;

  useEffect(() => {
    const promise = dispatch(getConstruction());
    return () => {
      promise.abort();
    };
  }, [dispatch]);

  const initialCong = {
    name: "",
    time: new Date().toISOString().split("T")[0],
    amount: "",
    complete: "true",
  };

  const [cong, setCong] = useState(initialCong);
  const handleFormTinhCong = (e) => {
    const newCong = {
      ...cong,
      [e.target.name]: e.target.value,
    };
    setCong(newCong);
  };

  const [isUpdate, setIsUpdate] = useState(false);

  const submitFormCong = (e) => {
    e.preventDefault();

    resetForm();
    isUpdate
      ? dispatch(updateConstruction_cong({ id_ct: id_ct, body: cong }))
      : dispatch(addConstruction_cong({ id_ct: id_ct, body: cong }));
    isUpdate && setIsUpdate(false);
  };
  const resetForm = () => {
    setCong(initialCong);
  };

  const handleUpdate = (i) => {
    setIsUpdate(true);
    setCong({
      ...i,
    });
  };

  const handleCancelUpdate = (i) => {
    setIsUpdate(false);
    setCong(initialCong);
  };

  const handleDelete = (id) => {
    // dispatch(deleteCong({ id_ct: id_ct, id_cong: id }));
    console.log(id_ct);
    dispatch(deleteConstruction_cong({ id_ct: id_ct, id_cong: id }));
    setCong(initialCong);
    setIsUpdate(false);
  };

  return (
    <>
      <div className="form_tinh_cong-close">
        <Link to="/thong-ke">
          <FontAwesomeIcon icon={faXmark} />
        </Link>
      </div>
      <div className="form_tk_box" style={{ marginTop: 24 }}>
        <form onSubmit={(e) => submitFormCong(e)} className="form_tk">
          <div className="form_tk_row">
            <div className="form_tk_row_left">
              <span>Tên chi phí: </span>
            </div>
            <div className="form_tk_row_right">
              <input
                type="text"
                name="name"
                onChange={(e) => handleFormTinhCong(e)}
                value={cong.name}
              />
            </div>
          </div>

          <div className="form_tk_row">
            <div className="form_tk_row_left">
              <span>Số tiền: </span>
            </div>

            <div className="form_tk_row_right" style={{ position: "relative" }}>
              <input
                type="text"
                name="amount"
                onChange={(e) => handleFormTinhCong(e)}
                value={cong.amount}
                placeholder=""
                style={{ width: "40%" }}
              />
              <span style={{ width: "60%" }}>.000 &#8363;</span>
              <br />
              <i
                style={{
                  fontSize: 12,
                  color: "#ccc",
                  marginTop: 6,
                  position: "absolute",
                  top: "100%",
                }}
              >
                {cong.add_price != "" &&
                  /^[0-9]+$/.test(cong.add_price) &&
                  `( ${numberToWordsVietnamese(
                    parseFloat(`${cong.add_price}000`)
                  )} VNĐ )`}
              </i>
            </div>
          </div>

          <div className="form_tk_row">
            <div className="form_tk_row_left"></div>
            <div className="form_tk_row_right complete">
              <input
                className="checkbox-box"
                type="checkbox"
                name="complete"
                onChange={(e) =>
                  handleFormTinhCong({
                    target: {
                      name: e.target.name,
                      value: e.target.checked ? "true" : "false",
                    },
                  })
                }
                checked={cong.complete === "true"} // Kiểm tra nếu giá trị là chuỗi "true"
              />

              <div className="complete-box">Đã thanh toán</div>
            </div>
          </div>

          <div className="form_tk_row">
            <div className="form_tk_row_left">
              <span>Thời gian:</span>
            </div>
            <div className="form_tk_row_right">
              <input
                type="date"
                name="time"
                onChange={(e) => handleFormTinhCong(e)}
                value={cong.time}
                style={{ color: "#333" }}
              />
            </div>
          </div>
          <br />

          <div className="form_tk_row">
            <div className="form_tk_row_right">
              <Button
                variant="primary"
                type="submit"
                disabled={!cong.name || !cong.amount}
                style={{ width: "100%", fontWeight: 700 }}
              >
                {isUpdate ? "Lưu" : "Thêm"}
              </Button>
            </div>
            <div className="form_tk_row_left">
              {isUpdate && (
                <Button
                  variant="secondary"
                  onClick={() => handleCancelUpdate()}
                  style={{ width: "100%", margin: "0 6px" }}
                >
                  Hủy sửa
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>

      <div className="table_box">
        {dataTinhCong?.length == 0 && (
          <p style={{ textAlign: "center", marginTop: 36, color: "gray" }}>
            ( Chưa có dữ liệu )
          </p>
        )}
        {dataTinhCong?.length > 0 && (
          <table className="table_form">
            <thead>
              <tr>
                <th>Tên chi phí </th>
                <th>Số tiền </th>
                <th>Thời gian </th>
                <th colSpan={2}>
                  {SetPrice(
                    dataTinhCong.reduce(
                      (tong, item) => parseFloat(item.amount) + tong,
                      0
                    ) * 1000
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {dataTinhCong?.map((i, index) => {
                return (
                  <tr key={i.id}>
                    <td>
                      <div
                        className={i.complete === "false" && "chua-thanh-toan"}
                      >
                        {i.name}
                      </div>
                    </td>

                    <td>{SetPrice(i.amount * 1000)}</td>
                    <td>{Moment(i.time)}</td>
                    <td>
                      {!isUpdate && (
                        <button onClick={() => handleUpdate(i)}>Sửa</button>
                      )}
                      {isUpdate && cong.id == i.id && (
                        <button onClick={() => handleCancelUpdate()}>
                          Hủy sửa
                        </button>
                      )}
                      {isUpdate && cong.id != i.id && (
                        <button onClick={() => handleUpdate(i)}> Sửa</button>
                      )}
                    </td>

                    <td>
                      <XacNhanXoa ham_xoa={handleDelete} id_xoa={i.id}>
                        <button>Xóa</button>
                      </XacNhanXoa>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default FormTinhCong;
