import React from "react";
import "./style.scss";
import { useMediaQuery } from "react-responsive";
import HeaderDestop from "./header-destop/HeaderDestop";
import HeaderMobile from "./header-mobile/HeaderMobile";

const Header = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="header">
      {isDesktop && <HeaderDestop />}
      {isMobile && <HeaderMobile />}
    </div>
  );
};

export default Header;
