import React from "react";
import "./style.scss";

const ContactDestop = () => {
  return (
    <div className="contact-destop">
      <div className="contact-destop-zalo">
        <a href="https://zalo.me/0964040895">
          <img src={process.env.PUBLIC_URL + "/images/zalo.png"} alt="" />
          <div>Chat Zalo</div>
        </a>
      </div>

      <div className="contact-destop-phone">
        <div className="img-phone-box">
          <img src={process.env.PUBLIC_URL + "/images/phone.png"} alt="" />
        </div>
        <div>096.4040.895</div>
      </div>

      <div className="contact-destop-fb">
        <a href="https://www.messenger.com/t/bienquangcaobmt">
          <img src={process.env.PUBLIC_URL + "/images/fb.png"} alt="" />
          <div>Chat Facebook</div>
        </a>
      </div>
    </div>
  );
};

export default ContactDestop;
