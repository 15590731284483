import "./App.scss";
import Page from "./page/Page";
import { Route, Routes } from "react-router-dom";
import PageDetail from "./page-detail/PageDetail";
import FormThongke from "./thongke/Form-thongke";
import FormTinhCong from "./thongke/Form-tinhcong";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<Page />} />
        <Route path="/:id" element={<PageDetail />} />
        <Route path="/thong-ke" element={<FormThongke />} />
        <Route path="/thong-ke/tinh-cong" element={<FormTinhCong />} />
      </Routes>
    </div>
  );
}

export default App;
