import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../util/http";

export const getConstruction = createAsyncThunk(
  "thongke/getConstruction",
  async (_, thunkAPI) => {
    const response = await http.get("construction/get", {
      signal: thunkAPI.signal,
    });
    return response.data;
  }
);

export const addConstruction = createAsyncThunk(
  "thongke/addConstruction",
  async (body, thunkAPI) => {
    try {
      const response = await http.post("construction/create", body, {
        signal: thunkAPI.signal,
      });

      return response.data;
    } catch (error) {
      if (error.name === "AxiosError" && error.response.status === 422) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const updateConstruction = createAsyncThunk(
  "thongke/updateConstruction",
  async (body, thunkAPI) => {
    try {
      const response = await http.put(
        `construction/update/${body.id_ct}`,
        body,
        {
          signal: thunkAPI.signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error.name === "AxiosError" && error.response.status === 422) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const deleteConstruction = createAsyncThunk(
  "thongke/deleteConstruction",
  async (body, thunkAPI) => {
    const response = await http.delete(`construction/delete/${body}`, {
      signal: thunkAPI.signal,
    });
    return response.data;
  }
);

//Tạo thêm sửa xóa cho công---------------

export const addConstruction_cong = createAsyncThunk(
  "thongke/addConstruction_cong",
  async (body, thunkAPI) => {
    try {
      const response = await http.post("construction/create_cong", body, {
        signal: thunkAPI.signal,
      });
      return response.data;
    } catch (error) {
      if (error.name === "AxiosError" && error.response.status === 422) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const updateConstruction_cong = createAsyncThunk(
  "thongke/updateConstruction_cong",
  async (body, thunkAPI) => {
    try {
      const response = await http.put(
        `construction/update_cong/${body.id_ct}`,
        body,
        {
          signal: thunkAPI.signal,
        }
      );
      return body;
    } catch (error) {
      if (error.name === "AxiosError" && error.response.status === 422) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const deleteConstruction_cong = createAsyncThunk(
  "thongke/deleteConstruction_cong",
  async (body, thunkAPI) => {
    const response = await http.delete(
      `construction/delete_cong/${body.id_ct}/${body.id_cong}`,
      {
        signal: thunkAPI.signal,
      }
    );
    return body;
  }
);

const thongkeSlice = createSlice({
  name: "thongke",
  initialState: [
    // {
    //   id_ct: "ct000001",
    //   address_ct: "01 - Phạm Ngũ Lão",
    //   time_ct: "1/2/2024",
    //   type_ct: "nha_moi",
    //   name_khach: "anh A",
    //   phone_khach: "0987654321",
    //   price_ct: 3000,
    //   cong: [
    //     {
    //       id: "000001",
    //       name: "Hiên",
    //       merit: "2",
    //       overtime: "1",
    //       add_price: "30",
    //       amount: "650",
    //     },
    //     {
    //       id: "000002",
    //       name: "Chung",
    //       merit: "1",
    //       overtime: "1",
    //       add_price: "30",
    //       amount: "350",
    //     },
    //   ],
    // },
    // {
    //   id_ct: "ct000002",
    //   address_ct: "12 - Ngô Quyền",
    //   time_ct: "1/1/2024",
    //   type_ct: "nha_cu",
    //   name_khach: "Chị B",
    //   phone_khach: "0987654888",
    //   price_ct: 6000,
    //   cong: [
    //     {
    //       id: "000001",
    //       name: "Mẫn",
    //       merit: "2",
    //       overtime: "1",
    //       add_price: "30",
    //       amount: "650",
    //     },
    //     {
    //       id: "000002",
    //       name: "Dũng",
    //       merit: "1",
    //       overtime: "1",
    //       add_price: "30",
    //       amount: "350",
    //     },
    //   ],
    // },
  ],
  reducers: {
    addCong: (state, action) => {
      const cong = state.find((i, index) => {
        return i.id_ct == action.payload.id_ct;
      })?.cong;
      cong.unshift(action.payload.body);
    },
    updateCong: (state, action) => {
      const cong = state.find((i, index) => {
        return i.id_ct == action.payload.id_ct;
      })?.cong;

      const indexCong = cong?.findIndex(
        (i, index) => i.id == action.payload.body.id
      );

      cong?.splice(indexCong, 1, action.payload.body);
    },
    deleteCong: (state, action) => {
      const cong = state.find((i, index) => {
        return i.id_ct == action.payload.id_ct;
      })?.cong;

      const indexCong = cong?.findIndex(
        (i, index) => i.id == action.payload.id_cong
      );
      cong?.splice(indexCong, 1);
    },
    add_ct: (state, action) => {
      state.unshift(action.payload);
    },
    update_ct: (state, action) => {
      const indexCt = state.findIndex(
        (i, index) => i.id_ct == action.payload.id_ct
      );
      state.splice(indexCt, 1, action.payload);
    },
    delete_ct: (state, action) => {
      const indexCt = state?.findIndex((i, index) => i.id_ct == action.payload);
      state.splice(indexCt, 1);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getConstruction.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(addConstruction.fulfilled, (state, action) => {
        state.unshift(action.payload);
      })
      .addCase(updateConstruction.fulfilled, (state, action) => {
        state.find((item, index) => {
          if (item.id_ct == action.payload.id_ct) {
            state[index] = action.payload;
            return true;
          }
          return false;
        });
      })
      .addCase(deleteConstruction.fulfilled, (state, action) => {
        const constructionId = action.meta.arg;
        const deleteIndex = state.findIndex(
          (item) => item.id_ct == constructionId
        );
        if (deleteIndex !== -1) {
          state.splice(deleteIndex, 1);
        }
      })
      .addCase(addConstruction_cong.fulfilled, (state, action) => {
        const cong = state.find((i, index) => {
          return i.id_ct == action.payload.id_ct;
        })?.cong;
        cong?.unshift(action.payload.cong[0]);
      })
      .addCase(updateConstruction_cong.fulfilled, (state, action) => {
        const cong = state.find((i, index) => {
          return i.id_ct == action.payload.id_ct;
        })?.cong;

        const indexCong = cong.findIndex(
          (i, index) => i.id == action.payload.body.id
        );
        cong.splice(indexCong, 1, action.payload.body);
      })
      .addCase(deleteConstruction_cong.fulfilled, (state, action) => {
        const cong = state.find((i, index) => {
          return i.id_ct == action.payload.id_ct;
        })?.cong;

        const indexCong = cong?.findIndex(
          (i, index) => i.id == action.payload.id_cong
        );
        cong?.splice(indexCong, 1);
      });
  },
});

export const { addCong, updateCong, deleteCong, add_ct, update_ct, delete_ct } =
  thongkeSlice.actions;
export const handleDataTinhCong = (state) => state.thongke;

export default thongkeSlice.reducer;
