import React from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import data_bh_detail from "../data";
import { Link } from "react-router-dom";
import { FacebookProvider, Page } from "react-facebook";

const Footer = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <>
      <div className="footer">
        <div className="footer-contact">
          <h3>Thông tin liên hệ</h3>

          <div className="img-box">
            <img
              src={
                process.env.PUBLIC_URL +
                "/images/logo-lam-bang-hieu-gia-re-tai-bmt-daklak.png"
              }
              alt="lam-bang-hieu-gia-re-tai-bmt-daklak"
            />
          </div>
          <div className="footer-contact-icon">
            <div>
              <h4>Công ty TNHH Quảng Cáo Phong Phát</h4>
            </div>
            <div>
              <span>
                <FontAwesomeIcon icon={faLocationDot} />
              </span>{" "}
              <span>Y Wang, Buôn Ma Thuột, Đắk Lắk</span>
            </div>
            <div>
              <span>
                <FontAwesomeIcon icon={faPhone} />
              </span>{" "}
              <span>Hotline: 096.4040.895 (zalo 24/7)</span>
            </div>
            <div>
              <span>
                <FontAwesomeIcon icon={faEnvelope} />
              </span>{" "}
              <span>bienquangcaobmt@gmail.com</span>
            </div>
          </div>
        </div>
        {isDesktop && (
          <ul className="footer-cat">
            <h3>Làm bảng hiệu</h3>
            {data_bh_detail.map((item, index) => (
              <li key={item.id}>
                <Link to={`/${item.timkiem}`}>{item.tieude}</Link>
              </li>
            ))}
          </ul>
        )}
        <div className="footer-social">
          <h3>Mạng xã hội</h3>
          <div className="footer-social-box">
            <span>
              <a href="https://www.facebook.com/bienquangcaobmt/">
                <img
                  src={process.env.PUBLIC_URL + "/images/ft_facebook.png"}
                  alt=""
                />
              </a>
            </span>
            <span>
              <a href="https://www.youtube.com/shorts/W089u48OrIQ">
                <img
                  src={process.env.PUBLIC_URL + "/images/ft_youtube.png"}
                  alt=""
                />
              </a>
            </span>
          </div>
          <div>
            <FacebookProvider appId="2238221736366394">
              <Page
                href="https://www.facebook.com/bienquangcaobmt/"
                tabs="timeline"
                height={70}
              />
            </FacebookProvider>
          </div>
        </div>
      </div>
      <div className="footer-designer">
        © 2010 - {new Date().getFullYear()} bienquangcaobmt.com designed by
        B.A.C
      </div>
    </>
  );
};

export default Footer;
