export function numberToWordsVietnamese(number) {
  const units = [
    "",
    "Một",
    "Hai",
    "Ba",
    "Bốn",
    "Năm",
    "Sáu",
    "Bảy",
    "Tám",
    "Chín",
  ];
  const teens = [
    "",
    "Mười Một",
    "Mười Hai",
    "Mười Ba",
    "Mười Bốn",
    "Mười Lăm",
    "Mười Sáu",
    "Mười Bảy",
    "Mười Tám",
    "Mười Chín",
  ];
  const tens = [
    "",
    "Mười",
    "Hai Mươi",
    "Ba Mươi",
    "Bốn Mươi",
    "Năm Mươi",
    "Sáu Mươi",
    "Bảy Mươi",
    "Tám Mươi",
    "Chín Mươi",
  ];

  function convertLessThanThousand(num) {
    if (num === 0) {
      return "";
    } else if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else if (num < 100) {
      return tens[Math.floor(num / 10)] + " " + units[num % 10];
    } else {
      return (
        units[Math.floor(num / 100)] +
        " Trăm " +
        convertLessThanThousand(num % 100)
      );
    }
  }

  function convertBillions(num) {
    if (num >= 1000000000) {
      return (
        convertLessThanThousand(Math.floor(num / 1000000000)) +
        " Tỷ " +
        convertMillions(num % 1000000000)
      );
    } else {
      return convertMillions(num);
    }
  }

  function convertMillions(num) {
    if (num >= 1000000) {
      return (
        convertLessThanThousand(Math.floor(num / 1000000)) +
        " Triệu " +
        convertThousands(num % 1000000)
      );
    } else {
      return convertThousands(num);
    }
  }

  function convertThousands(num) {
    if (num >= 1000) {
      return (
        convertLessThanThousand(Math.floor(num / 1000)) +
        " Nghìn " +
        convertLessThanThousand(num % 1000)
      );
    } else {
      return convertLessThanThousand(num);
    }
  }

  if (number === 0) {
    return "Không";
  } else {
    return convertBillions(number);
  }
}
