import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../common/header/Header";
import Reason from "../page/reason/Reason";
import Procedure from "../page/procedure/Procedure";
import Youtub from "../page/youtub/Youtub";
import Footer from "../common/footer/Footer";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";

import ContactMobile from "../page/contact/contact-mobile/ContactMobile";
import ContactDestop from "../page/contact/contact-destop/ContactDestop";
import About from "../page/about/About";
import data_bh_detail, { data_qc_other } from "../common/data";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PageDetail = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [dataBh, setDataBh] = useState();

  return (
    <HelmetProvider>
      {dataBh && (
        <Helmet>
          <title>{dataBh.title_seo}</title>
          <meta name="description" content={dataBh.des_seo} />
          <link
            rel="canonical"
            href={`https://www.bienquangcaobmt.com/${dataBh.timkiem}`}
          />
        </Helmet>
      )}

      <div className="page-detail">
        {isDesktop && (
          <div className="destop">
            <Header />
            <Detail dataBh={dataBh} setDataBh={setDataBh} />
            <About />
            <Reason />
            <Procedure />
            <Youtub />
            <Footer />
            <ContactDestop />
          </div>
        )}

        {isMobile && (
          <div className="">
            <Header />
            <Detail dataBh={dataBh} setDataBh={setDataBh} />
            <About />
            <Reason />
            <Procedure />
            <Youtub />
            <Footer />
            <ContactMobile />
          </div>
        )}
      </div>
    </HelmetProvider>
  );
};

const Detail = ({ dataBh, setDataBh }) => {
  const { id } = useParams();

  const [img_conlai, setImg_conlai] = useState();

  useEffect(() => {
    const temp = [...data_bh_detail, ...data_qc_other].find(
      (item) => item.timkiem === id
    );
    temp && setDataBh(temp);

    //Logic dưới để lấy các ảnh còn lại trong mảng hình ảnh
    const sl1 = temp.mota.length;
    const sl2 = temp.hinhanh.length;
    const sl3 = sl2 - sl1;

    if (sl3 === 0) {
      setImg_conlai([]);
    } else {
      setImg_conlai(temp.hinhanh.slice(-sl3));
    }
  }, [id, setDataBh]);

  return (
    dataBh && (
      <div className="detail">
        <h2>{dataBh.tieude}</h2>

        <div className="detail-des">
          {dataBh.mota.map((item, index) => {
            return (
              <div key={index} className="detail-des-box">
                <p>{item}</p>
                <div className="detail-img-box">
                  <img
                    src={process.env.PUBLIC_URL + dataBh.hinhanh[index].link}
                    alt={dataBh.hinhanh[index].alt}
                  />
                  <div className="img-mota">{dataBh.hinhanh[index].mota}</div>
                </div>
              </div>
            );
          })}

          {img_conlai?.length > 0 &&
            img_conlai.map((item, index) => {
              return (
                <div className="img-conlai-box" key={index}>
                  <img
                    src={process.env.PUBLIC_URL + item.link}
                    alt={item.alt}
                  />
                  <div className="img-mota">{item.mota}</div>
                </div>
              );
            })}
        </div>
        {/* <div className="detail-img">
          {dataBh.hinhanh.map((item, index) => {
            return (
              <img src={process.env.PUBLIC_URL + item.link} key={item.id} />
            );
          })}
        </div> */}

        <div className="marketting">
          Là đơn vị hàng đầu thi công thiết kế <b>{dataBh.tieude}</b> Quảng Cáo
          tại Đắk Lắk, BMT. Hơn 20 năm hành nghề chúng tôi hiểu khách hàng của
          mình. Cam kết giá rẻ nhất thị trường. Liên hệ báo giá miễn phí:{" "}
          <b>096.4040.895</b>
        </div>
      </div>
    )
  );
};

export default PageDetail;
