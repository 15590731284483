import React from "react";
import "./style.scss";

const About = () => {
  return (
    <div className="about">
      <h1>Biển quảng cáo BMT, Đắk Lắk</h1>
      <div className="about-noibat">
        <i>
          Bạn cần làm <b>Biển quảng cáo</b> cho hộ kinh doanh, công ty,... giá
          rẻ chất lượng? Bạn cần thiết kế hay sửa chữa bảng hiệu đèn led, bảng
          hiệu alu, bảng hiệu mica, bạt hiflex, neon, inox hay bảng hiệu gỗ tại{" "}
          <b>BMT, Đắk Lắk</b>?
        </i>
      </div>
      <div>
        <b>Biển quảng cáo BMT</b> Bạn cần tổ chức sự kiện, backdrop, triển lãm
        trưng bày quảng cáo thu hút và tìm dịch vụ quảng cáo gần, uy tín, chuyên
        nghiệp?
      </div>
      <br />
      <div>
        Chúng tôi là đơn vị tiên phong trong lĩnh vực quảng cáo tại BMT, Đắk
        Lắk. Hơn 20 năm hành nghề đã mang đến cho chúng tôi rất nhiều kinh
        nghiệm, nhiều niềm tin, nhiều nụ cười hạnh phúc của khách hàng. Điều đó
        vừa là niềm vui vừa là động lực để chúng tôi từng bước phát triển mang
        lại chất lượng tốt nhất cho khách hàng.
      </div>
      <br />
      <div>
        Để tìm dịch vụ <b>làm Biển Quảng Cáo giá rẻ</b> và uy tín tại Buôn Ma
        Thuột, Đắk Lắk quý khách hàng chỉ cần zalo hoặc call <b>096 4040 895</b>{" "}
        để được cử nhân viên báo giá, khảo sát tới tận địa chỉ nhé.
        <i> Cam Kết: Nghiệm thu hài lòng bảo hành bảo trì trọn gói.</i>
      </div>
    </div>
  );
};

export default About;
