import { useState } from "react";
import { updateConstruction } from "../thongke/thongkeSlice";
import { useDispatch } from "react-redux";

const SetModalNote = ({ value, setShowModalNote }) => {
  const dispatch = useDispatch();
  const [valueInput, setValueInput] = useState(value);
  const handleChangeInput = (e) => {
    setValueInput((pre) => {
      return { ...pre, note_ct: e.target.value };
    });
  };

  const handleSave = () => {
    const objUpdate = {
      note_ct: valueInput?.value || "",
    };

    var { _id, ...updtaeObject } = valueInput;

    //Hàm đóng cái modal nhỏ đó đi
    setShowModalNote((pre) => {
      return { ...pre, isShow: false };
    });
    dispatch(updateConstruction({ ...updtaeObject }));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          // width: "200px",
          // height: "60px",
          background: "gray",
          position: "absolute",
          bottom: "0px",
          right: "100%",
          boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
          padding: "12px",
          zIndex: 3,
        }}
      >
        <textarea
          rows="3"
          cols="18"
          style={{ padding: "0px 6px", marginRight: 12 }}
          placeholder="Ghi chú..."
          // defaultValue={""}
          onChange={(e) => handleChangeInput(e)}
        >
          {valueInput.note_ct}
        </textarea>
        <b
          style={{
            padding: 6,
            fontSize: 12,
            borderRadius: "4px",
            border: "1px solid #0D6EFD",
            background: "#0D6EFD",
            color: "#fff",
            alignSelf: "flex-end",
          }}
          onClick={() => handleSave()}
        >
          Lưu
        </b>
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            borderTop: "8px solid gray",
            position: "absolute",
            top: "99%",
            left: "88%",
          }}
        ></div>

        <i
          style={{
            position: "absolute",
            top: 2,
            right: 2,
            fontSize: 12,
            color: "#fff",
          }}
          className="fas fa-times-circle"
          onClick={() => {
            setShowModalNote((pre) => {
              return { ...pre, isShow: false };
            });
          }}
        ></i>
      </div>
    </>
  );
};

export default SetModalNote;
