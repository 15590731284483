import React, { useState } from "react";
import Button from "react-bootstrap/Button";

const XacNhanXoa = ({ children, ham_xoa, id_xoa }) => {
  const [showWarm, setShowWarm] = useState({
    show: false,
    id: "",
  });

  const ham_khi_nhan_xoa = () => {
    setShowWarm((pre) => {
      return {
        show: !pre.show,
        id: id_xoa,
      };
    });
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClick: ham_khi_nhan_xoa });
    }
    return child;
  });

  return (
    <div
      style={{
        position: "relative",
        zIndex: 1,
        color: "#fff",
      }}
    >
      {childrenWithProps}

      <div
        style={{
          display: showWarm.show && showWarm.id == id_xoa ? `block` : "none",
          position: "absolute",
          bottom: "0px",
          right: "120%",
          width: 130,
          textAlign: "center",
          background: "#333",
          color: "#fff",
          borderRadius: "6px",
          padding: 8,
          opacity: "0.9",
          zIndex: 9999,
        }}
      >
        <div>
          <p>📍 Xác nhận xóa ?</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              variant="secondary"
              style={{ fontSize: 12, color: "#fff" }}
              onClick={() => {
                ham_khi_nhan_xoa();
                ham_xoa(id_xoa);
              }}
            >
              Xóa
            </Button>
            <Button
              variant="primary"
              style={{
                fontSize: 12,
                marginLeft: 12,
                color: "#fff",
              }}
              onClick={() =>
                setShowWarm((pre) => {
                  return {
                    show: !pre.show,
                    id: id_xoa,
                  };
                })
              }
            >
              Hủy
            </Button>
          </div>
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: "8px solid #333",
              //borderRight: "8px solid transparent",
              borderTop: "8px solid transparent",
              borderBottom: "8px solid transparent",
              position: "absolute",
              top: "66%",
              left: "98%",
            }}
          ></div>
          <i
            style={{
              position: "absolute",
              top: 2,
              right: 2,
              fontSize: 12,
              color: "#fff",
            }}
            className="fas fa-times-circle"
            onClick={() => {
              setShowWarm((pre) => {
                return {
                  ...pre,
                  show: !pre.show,
                };
              });
            }}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default XacNhanXoa;
