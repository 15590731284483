import React from "react";
import "./style.scss";

const ContactMobile = () => {
  return (
    <div className="contact-mobile">
      <div className="contact-mobile-zalo">
        <a href="https://zalo.me/0964040895">
          <img src={process.env.PUBLIC_URL + "/images/zalo.png"} alt="" />
          <div>Chat Zalo</div>
        </a>
      </div>

      <div className="contact-mobile-phone">
        <a href="tel:0964040895">
          <div className="img-phone-box">
            <img src={process.env.PUBLIC_URL + "/images/phone.png"} alt="" />
          </div>
          <div>Gọi điện</div>
        </a>
      </div>

      <div className="contact-mobile-fb">
        <a href="https://www.messenger.com/t/bienquangcaobmt">
          <img src={process.env.PUBLIC_URL + "/images/fb.png"} alt="" />
          <div>Chat Facebook</div>
        </a>
      </div>
    </div>
  );
};

export default ContactMobile;
