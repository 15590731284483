import axios from "axios";

class Http {
  // instance: AxiosInstance
  constructor() {
    this.instance = axios.create({
      // baseURL: "https://backend-vesinhcongnghiep.vercel.app/",
      // baseURL: "http://localhost:5000/",
      baseURL: "https://backend-bienquangcaobmt.vercel.app/",
      timeout: 10000,
    });
  }
}

const http = new Http().instance;

export default http;
